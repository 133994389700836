.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}
.firefly::before,
.firefly::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}
.firefly::before {
  background: black;
  opacity: 0.4;
  animation: drift ease alternate infinite;
}
.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw yellow;
  animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  animation-name: move1;
}
.firefly:nth-child(1)::before {
  animation-duration: 11s;
}
.firefly:nth-child(1)::after {
  animation-duration: 11s, 7199ms;
  animation-delay: 0ms, 6976ms;
}

@keyframes move1 {
  0% {
    transform: translateX(3vw) translateY(31vh) scale(0.29);
  }
  5.26316% {
    transform: translateX(-31vw) translateY(-28vh) scale(0.5);
  }
  10.52632% {
    transform: translateX(-10vw) translateY(-33vh) scale(0.98);
  }
  15.78947% {
    transform: translateX(-13vw) translateY(-43vh) scale(0.46);
  }
  21.05263% {
    transform: translateX(12vw) translateY(-26vh) scale(0.37);
  }
  26.31579% {
    transform: translateX(50vw) translateY(-44vh) scale(0.26);
  }
  31.57895% {
    transform: translateX(40vw) translateY(-22vh) scale(0.54);
  }
  36.84211% {
    transform: translateX(-44vw) translateY(40vh) scale(0.58);
  }
  42.10526% {
    transform: translateX(0vw) translateY(-3vh) scale(0.83);
  }
  47.36842% {
    transform: translateX(-19vw) translateY(48vh) scale(0.32);
  }
  52.63158% {
    transform: translateX(10vw) translateY(-34vh) scale(0.97);
  }
  57.89474% {
    transform: translateX(-26vw) translateY(-46vh) scale(0.27);
  }
  63.15789% {
    transform: translateX(-27vw) translateY(-17vh) scale(0.65);
  }
  68.42105% {
    transform: translateX(11vw) translateY(-31vh) scale(0.35);
  }
  73.68421% {
    transform: translateX(10vw) translateY(-35vh) scale(0.33);
  }
  78.94737% {
    transform: translateX(-19vw) translateY(-10vh) scale(0.89);
  }
  84.21053% {
    transform: translateX(-3vw) translateY(-48vh) scale(0.79);
  }
  89.47368% {
    transform: translateX(-27vw) translateY(32vh) scale(0.62);
  }
  94.73684% {
    transform: translateX(49vw) translateY(39vh) scale(0.59);
  }
  100% {
    transform: translateX(-39vw) translateY(-8vh) scale(0.97);
  }
}

.firefly:nth-child(2) {
  animation-name: move2;
}
.firefly:nth-child(2)::before {
  animation-duration: 12s;
}
.firefly:nth-child(2)::after {
  animation-duration: 12s, 7773ms;
  animation-delay: 0ms, 1761ms;
}

@keyframes move2 {
  0% {
    transform: translateX(-28vw) translateY(-24vh) scale(0.89);
  }
  3.7037% {
    transform: translateX(-33vw) translateY(50vh) scale(0.38);
  }
  7.40741% {
    transform: translateX(26vw) translateY(-27vh) scale(0.44);
  }
  11.11111% {
    transform: translateX(46vw) translateY(40vh) scale(0.61);
  }
  14.81481% {
    transform: translateX(14vw) translateY(14vh) scale(0.88);
  }
  18.51852% {
    transform: translateX(39vw) translateY(40vh) scale(0.69);
  }
  22.22222% {
    transform: translateX(4vw) translateY(5vh) scale(0.37);
  }
  25.92593% {
    transform: translateX(-7vw) translateY(-41vh) scale(0.99);
  }
  29.62963% {
    transform: translateX(-1vw) translateY(-3vh) scale(0.93);
  }
  33.33333% {
    transform: translateX(-32vw) translateY(21vh) scale(0.53);
  }
  37.03704% {
    transform: translateX(36vw) translateY(-43vh) scale(0.9);
  }
  40.74074% {
    transform: translateX(-43vw) translateY(41vh) scale(0.43);
  }
  44.44444% {
    transform: translateX(-46vw) translateY(0vh) scale(0.8);
  }
  48.14815% {
    transform: translateX(-12vw) translateY(15vh) scale(0.73);
  }
  51.85185% {
    transform: translateX(12vw) translateY(5vh) scale(0.84);
  }
  55.55556% {
    transform: translateX(-47vw) translateY(25vh) scale(0.41);
  }
  59.25926% {
    transform: translateX(-20vw) translateY(-18vh) scale(0.78);
  }
  62.96296% {
    transform: translateX(-13vw) translateY(-5vh) scale(0.99);
  }
  66.66667% {
    transform: translateX(-8vw) translateY(45vh) scale(0.52);
  }
  70.37037% {
    transform: translateX(-12vw) translateY(-16vh) scale(0.97);
  }
  74.07407% {
    transform: translateX(-41vw) translateY(10vh) scale(0.61);
  }
  77.77778% {
    transform: translateX(-24vw) translateY(35vh) scale(0.82);
  }
  81.48148% {
    transform: translateX(-40vw) translateY(-31vh) scale(0.63);
  }
  85.18519% {
    transform: translateX(-46vw) translateY(-43vh) scale(0.44);
  }
  88.88889% {
    transform: translateX(-23vw) translateY(25vh) scale(0.57);
  }
  92.59259% {
    transform: translateX(-24vw) translateY(32vh) scale(0.76);
  }
  96.2963% {
    transform: translateX(-23vw) translateY(4vh) scale(0.74);
  }
  100% {
    transform: translateX(18vw) translateY(-3vh) scale(0.67);
  }
}

.firefly:nth-child(3) {
  animation-name: move3;
}
.firefly:nth-child(3)::before {
  animation-duration: 12s;
}
.firefly:nth-child(3)::after {
  animation-duration: 12s, 6716ms;
  animation-delay: 0ms, 8355ms;
}

@keyframes move3 {
  0% {
    transform: translateX(-39vw) translateY(-17vh) scale(0.6);
  }
  5.26316% {
    transform: translateX(-46vw) translateY(-25vh) scale(0.76);
  }
  10.52632% {
    transform: translateX(38vw) translateY(-26vh) scale(0.42);
  }
  15.78947% {
    transform: translateX(2vw) translateY(-15vh) scale(0.77);
  }
  21.05263% {
    transform: translateX(5vw) translateY(16vh) scale(0.72);
  }
  26.31579% {
    transform: translateX(19vw) translateY(35vh) scale(0.29);
  }
  31.57895% {
    transform: translateX(12vw) translateY(15vh) scale(0.91);
  }
  36.84211% {
    transform: translateX(-6vw) translateY(32vh) scale(0.99);
  }
  42.10526% {
    transform: translateX(-39vw) translateY(5vh) scale(0.69);
  }
  47.36842% {
    transform: translateX(33vw) translateY(-43vh) scale(0.75);
  }
  52.63158% {
    transform: translateX(10vw) translateY(-4vh) scale(0.75);
  }
  57.89474% {
    transform: translateX(-35vw) translateY(-29vh) scale(1);
  }
  63.15789% {
    transform: translateX(30vw) translateY(-48vh) scale(0.63);
  }
  68.42105% {
    transform: translateX(-25vw) translateY(-27vh) scale(0.94);
  }
  73.68421% {
    transform: translateX(28vw) translateY(-36vh) scale(0.35);
  }
  78.94737% {
    transform: translateX(25vw) translateY(-14vh) scale(0.9);
  }
  84.21053% {
    transform: translateX(-1vw) translateY(-19vh) scale(0.86);
  }
  89.47368% {
    transform: translateX(-11vw) translateY(-6vh) scale(0.95);
  }
  94.73684% {
    transform: translateX(-16vw) translateY(4vh) scale(0.47);
  }
  100% {
    transform: translateX(-45vw) translateY(46vh) scale(0.71);
  }
}

.firefly:nth-child(4) {
  animation-name: move4;
}
.firefly:nth-child(4)::before {
  animation-duration: 15s;
}
.firefly:nth-child(4)::after {
  animation-duration: 15s, 5206ms;
  animation-delay: 0ms, 3333ms;
}

@keyframes move4 {
  0% {
    transform: translateX(50vw) translateY(48vh) scale(0.9);
  }
  3.7037% {
    transform: translateX(-26vw) translateY(12vh) scale(0.89);
  }
  7.40741% {
    transform: translateX(35vw) translateY(-41vh) scale(0.33);
  }
  11.11111% {
    transform: translateX(-32vw) translateY(-41vh) scale(0.56);
  }
  14.81481% {
    transform: translateX(-33vw) translateY(37vh) scale(0.96);
  }
  18.51852% {
    transform: translateX(-27vw) translateY(18vh) scale(0.36);
  }
  22.22222% {
    transform: translateX(-30vw) translateY(9vh) scale(0.38);
  }
  25.92593% {
    transform: translateX(-21vw) translateY(7vh) scale(0.89);
  }
  29.62963% {
    transform: translateX(37vw) translateY(-9vh) scale(0.64);
  }
  33.33333% {
    transform: translateX(-26vw) translateY(-39vh) scale(0.74);
  }
  37.03704% {
    transform: translateX(-3vw) translateY(-30vh) scale(0.63);
  }
  40.74074% {
    transform: translateX(-20vw) translateY(50vh) scale(0.49);
  }
  44.44444% {
    transform: translateX(18vw) translateY(11vh) scale(0.27);
  }
  48.14815% {
    transform: translateX(-30vw) translateY(-34vh) scale(0.5);
  }
  51.85185% {
    transform: translateX(-18vw) translateY(-32vh) scale(0.82);
  }
  55.55556% {
    transform: translateX(31vw) translateY(4vh) scale(0.81);
  }
  59.25926% {
    transform: translateX(-4vw) translateY(15vh) scale(0.67);
  }
  62.96296% {
    transform: translateX(-43vw) translateY(2vh) scale(0.88);
  }
  66.66667% {
    transform: translateX(-46vw) translateY(22vh) scale(0.86);
  }
  70.37037% {
    transform: translateX(18vw) translateY(-48vh) scale(0.47);
  }
  74.07407% {
    transform: translateX(-4vw) translateY(-17vh) scale(0.75);
  }
  77.77778% {
    transform: translateX(-25vw) translateY(34vh) scale(0.89);
  }
  81.48148% {
    transform: translateX(-1vw) translateY(10vh) scale(0.98);
  }
  85.18519% {
    transform: translateX(-46vw) translateY(0vh) scale(0.55);
  }
  88.88889% {
    transform: translateX(8vw) translateY(49vh) scale(0.6);
  }
  92.59259% {
    transform: translateX(43vw) translateY(11vh) scale(0.82);
  }
  96.2963% {
    transform: translateX(-40vw) translateY(-4vh) scale(0.31);
  }
  100% {
    transform: translateX(42vw) translateY(0vh) scale(0.49);
  }
}

.firefly:nth-child(5) {
  animation-name: move5;
}
.firefly:nth-child(5)::before {
  animation-duration: 18s;
}
.firefly:nth-child(5)::after {
  animation-duration: 18s, 7554ms;
  animation-delay: 0ms, 6776ms;
}

@keyframes move5 {
  0% {
    transform: translateX(-10vw) translateY(-19vh) scale(0.49);
  }
  5.55556% {
    transform: translateX(0vw) translateY(-1vh) scale(0.77);
  }
  11.11111% {
    transform: translateX(21vw) translateY(33vh) scale(0.78);
  }
  16.66667% {
    transform: translateX(-4vw) translateY(-22vh) scale(0.39);
  }
  22.22222% {
    transform: translateX(26vw) translateY(-40vh) scale(0.31);
  }
  27.77778% {
    transform: translateX(8vw) translateY(-2vh) scale(0.66);
  }
  33.33333% {
    transform: translateX(-10vw) translateY(39vh) scale(0.4);
  }
  38.88889% {
    transform: translateX(1vw) translateY(-9vh) scale(0.9);
  }
  44.44444% {
    transform: translateX(-26vw) translateY(-35vh) scale(0.89);
  }
  50% {
    transform: translateX(14vw) translateY(37vh) scale(0.74);
  }
  55.55556% {
    transform: translateX(6vw) translateY(-14vh) scale(0.44);
  }
  61.11111% {
    transform: translateX(-2vw) translateY(16vh) scale(0.89);
  }
  66.66667% {
    transform: translateX(-5vw) translateY(41vh) scale(0.49);
  }
  72.22222% {
    transform: translateX(-38vw) translateY(-23vh) scale(1);
  }
  77.77778% {
    transform: translateX(-2vw) translateY(22vh) scale(0.89);
  }
  83.33333% {
    transform: translateX(-49vw) translateY(12vh) scale(0.98);
  }
  88.88889% {
    transform: translateX(30vw) translateY(4vh) scale(0.26);
  }
  94.44444% {
    transform: translateX(-35vw) translateY(26vh) scale(0.39);
  }
  100% {
    transform: translateX(-24vw) translateY(35vh) scale(1);
  }
}

.firefly:nth-child(6) {
  animation-name: move6;
}
.firefly:nth-child(6)::before {
  animation-duration: 11s;
}
.firefly:nth-child(6)::after {
  animation-duration: 11s, 9963ms;
  animation-delay: 0ms, 6503ms;
}

@keyframes move6 {
  0% {
    transform: translateX(0vw) translateY(24vh) scale(0.85);
  }
  5.55556% {
    transform: translateX(42vw) translateY(-47vh) scale(0.87);
  }
  11.11111% {
    transform: translateX(-44vw) translateY(-30vh) scale(0.46);
  }
  16.66667% {
    transform: translateX(10vw) translateY(3vh) scale(0.55);
  }
  22.22222% {
    transform: translateX(8vw) translateY(-14vh) scale(0.74);
  }
  27.77778% {
    transform: translateX(-16vw) translateY(37vh) scale(0.59);
  }
  33.33333% {
    transform: translateX(-30vw) translateY(42vh) scale(0.27);
  }
  38.88889% {
    transform: translateX(-30vw) translateY(44vh) scale(0.69);
  }
  44.44444% {
    transform: translateX(-22vw) translateY(-33vh) scale(0.28);
  }
  50% {
    transform: translateX(23vw) translateY(-29vh) scale(0.62);
  }
  55.55556% {
    transform: translateX(4vw) translateY(-32vh) scale(0.59);
  }
  61.11111% {
    transform: translateX(47vw) translateY(5vh) scale(0.92);
  }
  66.66667% {
    transform: translateX(-1vw) translateY(-14vh) scale(0.64);
  }
  72.22222% {
    transform: translateX(15vw) translateY(21vh) scale(0.28);
  }
  77.77778% {
    transform: translateX(27vw) translateY(-5vh) scale(0.98);
  }
  83.33333% {
    transform: translateX(-9vw) translateY(-7vh) scale(0.27);
  }
  88.88889% {
    transform: translateX(-41vw) translateY(-37vh) scale(0.62);
  }
  94.44444% {
    transform: translateX(8vw) translateY(-18vh) scale(0.87);
  }
  100% {
    transform: translateX(-48vw) translateY(38vh) scale(0.64);
  }
}

.firefly:nth-child(7) {
  animation-name: move7;
}
.firefly:nth-child(7)::before {
  animation-duration: 13s;
}
.firefly:nth-child(7)::after {
  animation-duration: 13s, 5615ms;
  animation-delay: 0ms, 7630ms;
}

@keyframes move7 {
  0% {
    transform: translateX(47vw) translateY(-24vh) scale(0.58);
  }
  5% {
    transform: translateX(-9vw) translateY(-44vh) scale(0.95);
  }
  10% {
    transform: translateX(6vw) translateY(49vh) scale(0.75);
  }
  15% {
    transform: translateX(-29vw) translateY(-46vh) scale(0.81);
  }
  20% {
    transform: translateX(-29vw) translateY(-3vh) scale(0.63);
  }
  25% {
    transform: translateX(-6vw) translateY(-8vh) scale(0.75);
  }
  30% {
    transform: translateX(-46vw) translateY(-6vh) scale(0.33);
  }
  35% {
    transform: translateX(48vw) translateY(-33vh) scale(0.99);
  }
  40% {
    transform: translateX(21vw) translateY(-32vh) scale(0.98);
  }
  45% {
    transform: translateX(47vw) translateY(3vh) scale(1);
  }
  50% {
    transform: translateX(-31vw) translateY(-36vh) scale(0.36);
  }
  55% {
    transform: translateX(4vw) translateY(-19vh) scale(0.43);
  }
  60% {
    transform: translateX(-4vw) translateY(-6vh) scale(0.5);
  }
  65% {
    transform: translateX(-20vw) translateY(14vh) scale(0.84);
  }
  70% {
    transform: translateX(5vw) translateY(-49vh) scale(0.69);
  }
  75% {
    transform: translateX(14vw) translateY(-35vh) scale(0.84);
  }
  80% {
    transform: translateX(-9vw) translateY(13vh) scale(0.5);
  }
  85% {
    transform: translateX(34vw) translateY(18vh) scale(0.87);
  }
  90% {
    transform: translateX(-15vw) translateY(-39vh) scale(0.3);
  }
  95% {
    transform: translateX(-47vw) translateY(12vh) scale(0.7);
  }
  100% {
    transform: translateX(-14vw) translateY(-29vh) scale(0.66);
  }
}

.firefly:nth-child(8) {
  animation-name: move8;
}
.firefly:nth-child(8)::before {
  animation-duration: 11s;
}
.firefly:nth-child(8)::after {
  animation-duration: 11s, 5721ms;
  animation-delay: 0ms, 4913ms;
}

@keyframes move8 {
  0% {
    transform: translateX(-38vw) translateY(45vh) scale(0.84);
  }
  5.26316% {
    transform: translateX(-20vw) translateY(-31vh) scale(0.27);
  }
  10.52632% {
    transform: translateX(27vw) translateY(-21vh) scale(0.75);
  }
  15.78947% {
    transform: translateX(36vw) translateY(19vh) scale(0.32);
  }
  21.05263% {
    transform: translateX(47vw) translateY(9vh) scale(0.65);
  }
  26.31579% {
    transform: translateX(-21vw) translateY(-4vh) scale(0.43);
  }
  31.57895% {
    transform: translateX(-7vw) translateY(-38vh) scale(0.82);
  }
  36.84211% {
    transform: translateX(-19vw) translateY(-6vh) scale(0.5);
  }
  42.10526% {
    transform: translateX(38vw) translateY(22vh) scale(0.46);
  }
  47.36842% {
    transform: translateX(-42vw) translateY(-30vh) scale(0.65);
  }
  52.63158% {
    transform: translateX(-23vw) translateY(34vh) scale(0.34);
  }
  57.89474% {
    transform: translateX(20vw) translateY(-49vh) scale(0.29);
  }
  63.15789% {
    transform: translateX(32vw) translateY(18vh) scale(0.55);
  }
  68.42105% {
    transform: translateX(2vw) translateY(-38vh) scale(0.72);
  }
  73.68421% {
    transform: translateX(-2vw) translateY(35vh) scale(0.89);
  }
  78.94737% {
    transform: translateX(-27vw) translateY(-18vh) scale(0.96);
  }
  84.21053% {
    transform: translateX(-29vw) translateY(-10vh) scale(0.74);
  }
  89.47368% {
    transform: translateX(-17vw) translateY(-20vh) scale(0.91);
  }
  94.73684% {
    transform: translateX(37vw) translateY(-27vh) scale(0.53);
  }
  100% {
    transform: translateX(-4vw) translateY(17vh) scale(0.96);
  }
}

.firefly:nth-child(9) {
  animation-name: move9;
}
.firefly:nth-child(9)::before {
  animation-duration: 14s;
}
.firefly:nth-child(9)::after {
  animation-duration: 14s, 7042ms;
  animation-delay: 0ms, 3472ms;
}

@keyframes move9 {
  0% {
    transform: translateX(-6vw) translateY(9vh) scale(0.64);
  }
  4% {
    transform: translateX(-13vw) translateY(49vh) scale(0.56);
  }
  8% {
    transform: translateX(-19vw) translateY(20vh) scale(0.79);
  }
  12% {
    transform: translateX(1vw) translateY(0vh) scale(0.46);
  }
  16% {
    transform: translateX(-36vw) translateY(9vh) scale(1);
  }
  20% {
    transform: translateX(-8vw) translateY(21vh) scale(0.35);
  }
  24% {
    transform: translateX(50vw) translateY(21vh) scale(0.95);
  }
  28% {
    transform: translateX(-41vw) translateY(7vh) scale(0.28);
  }
  32% {
    transform: translateX(-20vw) translateY(-45vh) scale(0.75);
  }
  36% {
    transform: translateX(-10vw) translateY(25vh) scale(0.82);
  }
  40% {
    transform: translateX(-16vw) translateY(18vh) scale(0.27);
  }
  44% {
    transform: translateX(40vw) translateY(-49vh) scale(0.42);
  }
  48% {
    transform: translateX(-32vw) translateY(28vh) scale(0.83);
  }
  52% {
    transform: translateX(16vw) translateY(-4vh) scale(0.65);
  }
  56% {
    transform: translateX(50vw) translateY(-38vh) scale(0.73);
  }
  60% {
    transform: translateX(10vw) translateY(49vh) scale(0.7);
  }
  64% {
    transform: translateX(-37vw) translateY(-19vh) scale(0.47);
  }
  68% {
    transform: translateX(-25vw) translateY(46vh) scale(0.96);
  }
  72% {
    transform: translateX(-7vw) translateY(47vh) scale(0.38);
  }
  76% {
    transform: translateX(40vw) translateY(-34vh) scale(0.48);
  }
  80% {
    transform: translateX(29vw) translateY(-47vh) scale(0.6);
  }
  84% {
    transform: translateX(37vw) translateY(-38vh) scale(0.59);
  }
  88% {
    transform: translateX(13vw) translateY(-19vh) scale(0.74);
  }
  92% {
    transform: translateX(-45vw) translateY(-24vh) scale(0.31);
  }
  96% {
    transform: translateX(-30vw) translateY(-9vh) scale(0.87);
  }
  100% {
    transform: translateX(-20vw) translateY(42vh) scale(0.39);
  }
}

.firefly:nth-child(10) {
  animation-name: move10;
}
.firefly:nth-child(10)::before {
  animation-duration: 17s;
}
.firefly:nth-child(10)::after {
  animation-duration: 17s, 5608ms;
  animation-delay: 0ms, 4699ms;
}

@keyframes move10 {
  0% {
    transform: translateX(17vw) translateY(48vh) scale(0.6);
  }
  4.16667% {
    transform: translateX(-24vw) translateY(0vh) scale(0.26);
  }
  8.33333% {
    transform: translateX(41vw) translateY(30vh) scale(0.86);
  }
  12.5% {
    transform: translateX(43vw) translateY(45vh) scale(0.8);
  }
  16.66667% {
    transform: translateX(-26vw) translateY(-16vh) scale(0.92);
  }
  20.83333% {
    transform: translateX(-6vw) translateY(19vh) scale(0.49);
  }
  25% {
    transform: translateX(-3vw) translateY(-14vh) scale(0.79);
  }
  29.16667% {
    transform: translateX(-48vw) translateY(42vh) scale(0.9);
  }
  33.33333% {
    transform: translateX(-17vw) translateY(-24vh) scale(0.46);
  }
  37.5% {
    transform: translateX(-46vw) translateY(-36vh) scale(0.37);
  }
  41.66667% {
    transform: translateX(28vw) translateY(12vh) scale(0.4);
  }
  45.83333% {
    transform: translateX(17vw) translateY(-46vh) scale(0.69);
  }
  50% {
    transform: translateX(-19vw) translateY(28vh) scale(1);
  }
  54.16667% {
    transform: translateX(-41vw) translateY(44vh) scale(0.96);
  }
  58.33333% {
    transform: translateX(-32vw) translateY(24vh) scale(0.51);
  }
  62.5% {
    transform: translateX(-28vw) translateY(28vh) scale(0.44);
  }
  66.66667% {
    transform: translateX(-17vw) translateY(47vh) scale(0.77);
  }
  70.83333% {
    transform: translateX(3vw) translateY(14vh) scale(0.49);
  }
  75% {
    transform: translateX(30vw) translateY(-1vh) scale(0.97);
  }
  79.16667% {
    transform: translateX(40vw) translateY(48vh) scale(0.79);
  }
  83.33333% {
    transform: translateX(2vw) translateY(-2vh) scale(0.98);
  }
  87.5% {
    transform: translateX(-48vw) translateY(-11vh) scale(0.72);
  }
  91.66667% {
    transform: translateX(35vw) translateY(-45vh) scale(0.55);
  }
  95.83333% {
    transform: translateX(32vw) translateY(48vh) scale(0.39);
  }
  100% {
    transform: translateX(41vw) translateY(-25vh) scale(0.58);
  }
}

.firefly:nth-child(11) {
  animation-name: move11;
}
.firefly:nth-child(11)::before {
  animation-duration: 10s;
}
.firefly:nth-child(11)::after {
  animation-duration: 10s, 5714ms;
  animation-delay: 0ms, 3965ms;
}

@keyframes move11 {
  0% {
    transform: translateX(13vw) translateY(-37vh) scale(0.51);
  }
  4.34783% {
    transform: translateX(-48vw) translateY(-29vh) scale(0.46);
  }
  8.69565% {
    transform: translateX(33vw) translateY(-34vh) scale(0.29);
  }
  13.04348% {
    transform: translateX(16vw) translateY(48vh) scale(0.98);
  }
  17.3913% {
    transform: translateX(46vw) translateY(35vh) scale(0.86);
  }
  21.73913% {
    transform: translateX(-2vw) translateY(48vh) scale(0.99);
  }
  26.08696% {
    transform: translateX(29vw) translateY(15vh) scale(1);
  }
  30.43478% {
    transform: translateX(-4vw) translateY(20vh) scale(0.26);
  }
  34.78261% {
    transform: translateX(44vw) translateY(47vh) scale(0.54);
  }
  39.13043% {
    transform: translateX(-44vw) translateY(31vh) scale(0.78);
  }
  43.47826% {
    transform: translateX(26vw) translateY(-1vh) scale(0.61);
  }
  47.82609% {
    transform: translateX(22vw) translateY(11vh) scale(0.92);
  }
  52.17391% {
    transform: translateX(36vw) translateY(-26vh) scale(0.68);
  }
  56.52174% {
    transform: translateX(-14vw) translateY(-10vh) scale(0.52);
  }
  60.86957% {
    transform: translateX(-20vw) translateY(14vh) scale(0.7);
  }
  65.21739% {
    transform: translateX(9vw) translateY(-43vh) scale(0.32);
  }
  69.56522% {
    transform: translateX(-1vw) translateY(-21vh) scale(0.54);
  }
  73.91304% {
    transform: translateX(8vw) translateY(-44vh) scale(0.67);
  }
  78.26087% {
    transform: translateX(-28vw) translateY(2vh) scale(0.9);
  }
  82.6087% {
    transform: translateX(-33vw) translateY(49vh) scale(0.51);
  }
  86.95652% {
    transform: translateX(-44vw) translateY(-31vh) scale(0.59);
  }
  91.30435% {
    transform: translateX(8vw) translateY(-9vh) scale(0.95);
  }
  95.65217% {
    transform: translateX(6vw) translateY(24vh) scale(0.26);
  }
  100% {
    transform: translateX(27vw) translateY(-40vh) scale(0.5);
  }
}

.firefly:nth-child(12) {
  animation-name: move12;
}
.firefly:nth-child(12)::before {
  animation-duration: 9s;
}
.firefly:nth-child(12)::after {
  animation-duration: 9s, 6000ms;
  animation-delay: 0ms, 2448ms;
}

@keyframes move12 {
  0% {
    transform: translateX(-24vw) translateY(16vh) scale(0.54);
  }
  3.57143% {
    transform: translateX(-4vw) translateY(-44vh) scale(0.73);
  }
  7.14286% {
    transform: translateX(-25vw) translateY(-9vh) scale(0.49);
  }
  10.71429% {
    transform: translateX(42vw) translateY(-10vh) scale(0.52);
  }
  14.28571% {
    transform: translateX(-4vw) translateY(48vh) scale(0.98);
  }
  17.85714% {
    transform: translateX(50vw) translateY(-25vh) scale(0.77);
  }
  21.42857% {
    transform: translateX(-15vw) translateY(49vh) scale(0.92);
  }
  25% {
    transform: translateX(-23vw) translateY(15vh) scale(0.9);
  }
  28.57143% {
    transform: translateX(-2vw) translateY(-29vh) scale(0.72);
  }
  32.14286% {
    transform: translateX(7vw) translateY(-19vh) scale(1);
  }
  35.71429% {
    transform: translateX(23vw) translateY(-42vh) scale(0.89);
  }
  39.28571% {
    transform: translateX(-27vw) translateY(-44vh) scale(0.73);
  }
  42.85714% {
    transform: translateX(-37vw) translateY(-43vh) scale(0.54);
  }
  46.42857% {
    transform: translateX(-28vw) translateY(36vh) scale(0.94);
  }
  50% {
    transform: translateX(15vw) translateY(-17vh) scale(0.9);
  }
  53.57143% {
    transform: translateX(35vw) translateY(35vh) scale(0.38);
  }
  57.14286% {
    transform: translateX(5vw) translateY(28vh) scale(0.59);
  }
  60.71429% {
    transform: translateX(-23vw) translateY(-24vh) scale(0.73);
  }
  64.28571% {
    transform: translateX(-42vw) translateY(26vh) scale(0.82);
  }
  67.85714% {
    transform: translateX(31vw) translateY(-49vh) scale(0.97);
  }
  71.42857% {
    transform: translateX(-30vw) translateY(-43vh) scale(0.88);
  }
  75% {
    transform: translateX(-46vw) translateY(42vh) scale(0.43);
  }
  78.57143% {
    transform: translateX(-45vw) translateY(-20vh) scale(0.91);
  }
  82.14286% {
    transform: translateX(8vw) translateY(-17vh) scale(0.45);
  }
  85.71429% {
    transform: translateX(-29vw) translateY(-29vh) scale(0.63);
  }
  89.28571% {
    transform: translateX(-36vw) translateY(39vh) scale(0.79);
  }
  92.85714% {
    transform: translateX(-40vw) translateY(-30vh) scale(0.42);
  }
  96.42857% {
    transform: translateX(23vw) translateY(-17vh) scale(0.43);
  }
  100% {
    transform: translateX(20vw) translateY(14vh) scale(0.83);
  }
}

.firefly:nth-child(13) {
  animation-name: move13;
}
.firefly:nth-child(13)::before {
  animation-duration: 12s;
}
.firefly:nth-child(13)::after {
  animation-duration: 12s, 6413ms;
  animation-delay: 0ms, 5735ms;
}

@keyframes move13 {
  0% {
    transform: translateX(12vw) translateY(43vh) scale(0.66);
  }
  5.55556% {
    transform: translateX(43vw) translateY(3vh) scale(0.4);
  }
  11.11111% {
    transform: translateX(11vw) translateY(19vh) scale(0.27);
  }
  16.66667% {
    transform: translateX(-39vw) translateY(-21vh) scale(0.57);
  }
  22.22222% {
    transform: translateX(10vw) translateY(23vh) scale(0.89);
  }
  27.77778% {
    transform: translateX(-31vw) translateY(-12vh) scale(0.59);
  }
  33.33333% {
    transform: translateX(49vw) translateY(-34vh) scale(0.81);
  }
  38.88889% {
    transform: translateX(46vw) translateY(-37vh) scale(0.63);
  }
  44.44444% {
    transform: translateX(34vw) translateY(-9vh) scale(0.69);
  }
  50% {
    transform: translateX(-37vw) translateY(0vh) scale(0.88);
  }
  55.55556% {
    transform: translateX(-49vw) translateY(-10vh) scale(0.36);
  }
  61.11111% {
    transform: translateX(-19vw) translateY(-7vh) scale(0.48);
  }
  66.66667% {
    transform: translateX(19vw) translateY(-27vh) scale(0.55);
  }
  72.22222% {
    transform: translateX(-9vw) translateY(22vh) scale(0.53);
  }
  77.77778% {
    transform: translateX(-38vw) translateY(6vh) scale(0.66);
  }
  83.33333% {
    transform: translateX(-7vw) translateY(18vh) scale(0.96);
  }
  88.88889% {
    transform: translateX(35vw) translateY(11vh) scale(0.93);
  }
  94.44444% {
    transform: translateX(41vw) translateY(-13vh) scale(0.44);
  }
  100% {
    transform: translateX(26vw) translateY(45vh) scale(0.79);
  }
}

.firefly:nth-child(14) {
  animation-name: move14;
}
.firefly:nth-child(14)::before {
  animation-duration: 17s;
}
.firefly:nth-child(14)::after {
  animation-duration: 17s, 6424ms;
  animation-delay: 0ms, 4886ms;
}

@keyframes move14 {
  0% {
    transform: translateX(43vw) translateY(20vh) scale(0.6);
  }
  5% {
    transform: translateX(-36vw) translateY(-22vh) scale(0.38);
  }
  10% {
    transform: translateX(25vw) translateY(-46vh) scale(0.99);
  }
  15% {
    transform: translateX(37vw) translateY(-29vh) scale(0.69);
  }
  20% {
    transform: translateX(-38vw) translateY(40vh) scale(0.94);
  }
  25% {
    transform: translateX(48vw) translateY(38vh) scale(0.88);
  }
  30% {
    transform: translateX(-15vw) translateY(-7vh) scale(0.26);
  }
  35% {
    transform: translateX(49vw) translateY(49vh) scale(0.6);
  }
  40% {
    transform: translateX(20vw) translateY(29vh) scale(0.52);
  }
  45% {
    transform: translateX(24vw) translateY(-10vh) scale(0.33);
  }
  50% {
    transform: translateX(-32vw) translateY(-1vh) scale(0.77);
  }
  55% {
    transform: translateX(-40vw) translateY(41vh) scale(0.83);
  }
  60% {
    transform: translateX(-15vw) translateY(27vh) scale(0.48);
  }
  65% {
    transform: translateX(28vw) translateY(-2vh) scale(0.45);
  }
  70% {
    transform: translateX(-48vw) translateY(-34vh) scale(0.54);
  }
  75% {
    transform: translateX(8vw) translateY(49vh) scale(0.47);
  }
  80% {
    transform: translateX(27vw) translateY(27vh) scale(0.76);
  }
  85% {
    transform: translateX(44vw) translateY(19vh) scale(0.95);
  }
  90% {
    transform: translateX(37vw) translateY(0vh) scale(0.75);
  }
  95% {
    transform: translateX(22vw) translateY(4vh) scale(0.48);
  }
  100% {
    transform: translateX(-2vw) translateY(43vh) scale(0.34);
  }
}

.firefly:nth-child(15) {
  animation-name: move15;
}
.firefly:nth-child(15)::before {
  animation-duration: 14s;
}
.firefly:nth-child(15)::after {
  animation-duration: 14s, 9184ms;
  animation-delay: 0ms, 3212ms;
}

@keyframes move15 {
  0% {
    transform: translateX(-5vw) translateY(-28vh) scale(0.77);
  }
  4.54545% {
    transform: translateX(-42vw) translateY(-8vh) scale(0.46);
  }
  9.09091% {
    transform: translateX(45vw) translateY(-32vh) scale(0.76);
  }
  13.63636% {
    transform: translateX(5vw) translateY(41vh) scale(0.84);
  }
  18.18182% {
    transform: translateX(24vw) translateY(-3vh) scale(0.58);
  }
  22.72727% {
    transform: translateX(-9vw) translateY(-27vh) scale(0.32);
  }
  27.27273% {
    transform: translateX(-32vw) translateY(11vh) scale(0.66);
  }
  31.81818% {
    transform: translateX(25vw) translateY(21vh) scale(0.57);
  }
  36.36364% {
    transform: translateX(-31vw) translateY(23vh) scale(0.87);
  }
  40.90909% {
    transform: translateX(18vw) translateY(-22vh) scale(0.81);
  }
  45.45455% {
    transform: translateX(7vw) translateY(-22vh) scale(0.45);
  }
  50% {
    transform: translateX(4vw) translateY(-24vh) scale(0.44);
  }
  54.54545% {
    transform: translateX(-7vw) translateY(19vh) scale(0.61);
  }
  59.09091% {
    transform: translateX(22vw) translateY(-20vh) scale(0.5);
  }
  63.63636% {
    transform: translateX(24vw) translateY(34vh) scale(0.77);
  }
  68.18182% {
    transform: translateX(-23vw) translateY(41vh) scale(0.27);
  }
  72.72727% {
    transform: translateX(-38vw) translateY(30vh) scale(0.37);
  }
  77.27273% {
    transform: translateX(37vw) translateY(-14vh) scale(0.87);
  }
  81.81818% {
    transform: translateX(18vw) translateY(-42vh) scale(0.61);
  }
  86.36364% {
    transform: translateX(1vw) translateY(45vh) scale(0.77);
  }
  90.90909% {
    transform: translateX(48vw) translateY(-29vh) scale(0.63);
  }
  95.45455% {
    transform: translateX(-16vw) translateY(14vh) scale(0.47);
  }
  100% {
    transform: translateX(-40vw) translateY(-32vh) scale(0.28);
  }
}

@keyframes drift {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0%,
  30%,
  100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow;
  }
}
