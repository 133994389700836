#root {
  height: 100%;
}

.ant-layout {
  height: 100%;
}

.login-form-button {
  margin-left: 0px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  padding: 20px;
}

input {
  padding: 10px;
  border-radius: 4px;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.styled-table {
  margin: 25px 0;
  font-size: 0.9em;
  width: 100%;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.ant-modal-content {
  overflow: scroll;
  border-radius: 8px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media only screen and (min-width: 580px) {
  .no-label {
    margin-left: 90px;
    width: calc(100% - 100px);
  }
}